


























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import Invitations from "@/views/groups/components/GroupInvitations.vue";
import PublicGroups from "@/views/groups/components/PublicGroups.vue";
import CompanyGroups from "@/views/groups/components/CompanyGroups.vue";
import { MyGroups } from "@/views/groups/components";
import { hasPermissions } from "@/core/utils/auth";

@Component({
  methods: { hasPermissions },
  components: {
    Invitations,
    PublicGroups,
    CompanyGroups,
    MyGroups,
  },
})
export default class Groups extends Vue {
  @Getter("groups/loadingGroups") loadingGroups!: boolean;
  @Getter("groups/loadingPublicGroups") loadingPublicGroups!: boolean;
  @Getter("groups/loadingCompanyGroups") loadingCompanyGroups!: boolean;
  @Getter("company/hasCompany") hasCompany!: boolean;
}
