import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"groups-page"}},[_c(VOverlay,{staticClass:"d-flex align-center justify-center",attrs:{"absolute":"","value":_vm.loadingGroups &&
      _vm.loadingPublicGroups &&
      _vm.hasCompany &&
      _vm.loadingCompanyGroups}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VContainer,{attrs:{"fluid":""}},[_c('Invitations'),(_vm.hasCompany)?_c('CompanyGroups',{staticClass:"mb-9"}):_vm._e(),_c('MyGroups'),_c('PublicGroups')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }